import React from "react"
import { ApolloProvider } from "@apollo/client"
import { client } from "./client"
import { AuthStoreProvider } from "@myaccount/website-service"
import { QueryClient, QueryClientProvider } from "react-query"

const enableMyAccount =
  (process.env.GATSBY_MYACCOUNT || null) === "0" ? false : true

const queryClient = new QueryClient()

export const wrapRootElement = ({ element }) => (
  <QueryClientProvider client={queryClient}>
    <AuthStoreProvider>
      <div className={enableMyAccount ? "has-myacc" : "no-myacc"}>
        <ApolloProvider client={client}>{element}</ApolloProvider>
      </div>
    </AuthStoreProvider>
  </QueryClientProvider>
)
