// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-off-plan-properties-for-rent-index-js": () => import("./../../../src/pages/off-plan-properties/for-rent/index.js" /* webpackChunkName: "component---src-pages-off-plan-properties-for-rent-index-js" */),
  "component---src-pages-off-plan-properties-for-sale-index-js": () => import("./../../../src/pages/off-plan-properties/for-sale/index.js" /* webpackChunkName: "component---src-pages-off-plan-properties-for-sale-index-js" */),
  "component---src-pages-off-plan-properties-map-for-sale-index-js": () => import("./../../../src/pages/off-plan-properties-map/for-sale/index.js" /* webpackChunkName: "component---src-pages-off-plan-properties-map-for-sale-index-js" */),
  "component---src-pages-property-for-rent-index-js": () => import("./../../../src/pages/property/for-rent/index.js" /* webpackChunkName: "component---src-pages-property-for-rent-index-js" */),
  "component---src-pages-property-for-sale-index-js": () => import("./../../../src/pages/property/for-sale/index.js" /* webpackChunkName: "component---src-pages-property-for-sale-index-js" */),
  "component---src-pages-property-map-for-rent-index-js": () => import("./../../../src/pages/property-map/for-rent/index.js" /* webpackChunkName: "component---src-pages-property-map-for-rent-index-js" */),
  "component---src-pages-property-map-for-sale-index-js": () => import("./../../../src/pages/property-map/for-sale/index.js" /* webpackChunkName: "component---src-pages-property-map-for-sale-index-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-areaguide-detail-template-js": () => import("./../../../src/templates/areaguide-detail-template.js" /* webpackChunkName: "component---src-templates-areaguide-detail-template-js" */),
  "component---src-templates-careers-details-template-js": () => import("./../../../src/templates/careers-details-template.js" /* webpackChunkName: "component---src-templates-careers-details-template-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/default-template.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/home-template.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-news-details-template-js": () => import("./../../../src/templates/news-details-template.js" /* webpackChunkName: "component---src-templates-news-details-template-js" */),
  "component---src-templates-offplan-details-js": () => import("./../../../src/templates/offplan-details.js" /* webpackChunkName: "component---src-templates-offplan-details-js" */),
  "component---src-templates-property-details-js": () => import("./../../../src/templates/property-details.js" /* webpackChunkName: "component---src-templates-property-details-js" */),
  "component---src-templates-team-details-template-js": () => import("./../../../src/templates/team-details-template.js" /* webpackChunkName: "component---src-templates-team-details-template-js" */)
}

